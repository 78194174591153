import React from "react"
import useMediaQuery from "../../Hooks/useMediaQuery"

const Ddl = ({items, def, config, label, onSelected}) => {
    const isSmall = useMediaQuery('(max-width: 500px)')
    const isMedium = useMediaQuery('(max-width: 720px)')
    const [open, setOpen] = React.useState(false)
    const [selected, setSelected] = React.useState(def)
    const handleSelected = (e) => {
        setSelected(e.target.id == def ? null : e.target.id)
        onSelected(e.target.id == def ? null : e.target.id)
    }

    return(
        <div style={{ display: 'flex', justifyContent: isMedium ? 'space-between' : 'start', width: isMedium ? '100%' : '', marginRight: isMedium ? 0 :'.5em', marginLeft: isMedium ? 0 : 5}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <div style={{marginRight: '.5em'}}>{label}</div>
            </div>
            <div onClick={() => {setOpen(!open)}} style={{cursor: 'pointer', display: 'flex', position: 'relative'}}>
            {
                open &&
                    <div style={{borderRadius: config.borderRadius, top: 0, zIndex: 100, position: 'absolute', minWidth: '7em',cursor: 'pointer', display: 'flex', flexDirection: 'column', backgroundColor: config['plot_bgcolor'], borderRadius: '8px', border: `1px solid ${config['buttonBorder']}`}}>
                        {
                            [def, ...items].map((v, i) => {
                                return <div key={`ddl_item_${v}`} style={{padding: '.5em'}} id={v} onClick={handleSelected}>{v}</div>
                            })
                        }
                    </div>
            }
            <div style={{paddingLeft: '.25em', paddingRight: '.25em', paddingTop: '.15em', height: '2em',display: 'flex', backgroundColor: config.plot_bgcolor, padding: '.3rem', borderRadius: config.borderRadius, border: `1px solid ${config['buttonBorder']}`}}>
                {selected || def} 
            </div>
            </div>
        </div>
    )
}
//&#x25BE;
export default Ddl
