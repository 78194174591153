// https://plot.ly/javascript/gauge-charts/#custom-gauge-chart
import React from "react"
import Plot from "react-plotly.js"

const getStuff = (config, title, value, max) => {
    const data = [
    {
        value: value,
        title: {
            text: title,
            font: {
                color: config.fontColor,
                family: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
                size: 17
              }
        },
        type: "indicator",
        mode: "gauge+number",
        gauge: {
        bordercolor: config.plot_bgcolor,
        bgcolor: config.plot_bgcolor,
        axis: {
            range: [0, max],
            visible: false,
            line: {color: config.fontColor}
        },
        bar: {
            color: config.lineColor,
            line: {color: config.fontColor}
        },
        }
    }
    ]

    const layout = {
        autosize: false,
        font: {
            color: config.fontColor,
            family: 'roboto'
          },
        height: 220,
        width: 200, //plotElement && plotElement.clientWidth || 500,
        margin: {l:0, t:0, r:0, b:0},
        font: {color: config.fontColor},
        plot_bgcolor: config.plot_bgcolor, // background color of the chart gauge
        paper_bgcolor: config.paper_bgcolor // background render area behind chart
    }

    return {data, layout}
}

const Gauge = ({config, title, value}) => {
    const stuff = getStuff(config, title, value)
    return (
        <Plot style={{display: 'flex', justifyContent: 'center'}} data={stuff.data} layout={stuff.layout} config={{displaylogo: false, responsive: true, modeBarButtonsToRemove: ['toImage']}} />
    )
}

export default Gauge
