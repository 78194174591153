import React from "react"
import config from '../../config.json';
import {
    Link,
} from "react-router-dom";

import SensorDetails from "../SensorDetails/SensorDetails";

const EquipmentDetails = ({ onCloseClick }) => {
    const [sensorIds, setSensorIds] = React.useState([])

    React.useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('sensor_ids');
        setSensorIds(myParam.split(','))
    }, [])

    const renderSensorDetails = () => {
        return sensorIds.map((sensor_id, index) => {
            return (
                <div key={"sensor_detail_"+sensor_id}>
                    <SensorDetails sensor_id={sensor_id} onCloseClick={onCloseClick}>
                    </SensorDetails>
                </div>
            )

        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh', backgroundColor: config.bgcolor, padding: '4px' }}>
        <div style={{ display: 'flex', width: '100%', paddingLeft: '1em', paddingRight: '1em', justifyContent: 'flex-end', backgroundColor: config.paper_bgcolor, border: '1px solid grey', borderRadius: '8px', marginBottom: '4px' }}>
            <Link to="/">
                <div style={{ cursor: 'pointer', fontWeight: 'bold' }}>back</div>
            </Link>
        </div>
            { sensorIds !== [] && renderSensorDetails()}
        </div>

    )
}
export default EquipmentDetails
