import React from "react"
import { apiRequest } from "../../App"

const CWT = ({ resource, cutoffFreq }) => {
    const [cwtUrl, setCwtUrl] = React.useState(null)
    const [freqRange, setfreqRange] = React.useState([])
    const [selectedRpm, setselectedRpm] = React.useState(0)
    const [sliderPosition, setSliderPosition] = React.useState(null)
    const [sampleCount, setSampleCount] = React.useState(null)
    const [sampleRate, setSampleRate] = React.useState(null)
    const [resizeListener, setResizeListener] = React.useState(null)
    const [lastSelected, setLastSelected] = React.useState(0)

    const sliderRef = React.useRef()

    React.useEffect(() => {
        async function getImgBlob() {
            let newBlob = await apiRequest(`${process.env.REACT_APP_API_URL}/v1/sg/${resource}?cutoff_freq=${cutoffFreq}&usejson=true`)
            let urlCreator = window.URL || window.webkitURL
            let result = await fetch(newBlob.image_bytes).then(res => res.blob())
            let newCwtUrl = urlCreator.createObjectURL(result)

            setCwtUrl(newCwtUrl)
            setfreqRange(newBlob.freqs)
            setSampleCount(newBlob.num_samples)
            setSampleRate(newBlob.sample_rate)

        }
        getImgBlob()
    }, [resource, cutoffFreq])

    React.useEffect(() => {


        window.removeEventListener("resize", resizeListener)
        let rsListener = window.addEventListener("resize", () => {
            if (sliderRef.current) {
                const rp = selectedRpm / cutoffFreq
                const np = Math.ceil(sliderRef.current.parentElement.getBoundingClientRect().height - rp * sliderRef.current.parentElement.getBoundingClientRect().height)
                setSliderPosition(np)
            }
        });
        setResizeListener(rsListener)
        if (sliderRef.current) {
            const rp = selectedRpm / cutoffFreq
            const np = Math.ceil(sliderRef.current.parentElement.getBoundingClientRect().height - rp * sliderRef.current.parentElement.getBoundingClientRect().height)
            setSliderPosition(np)
            setselectedRpm(selectedRpm)
        } else {
            setselectedRpm(0)
            setSliderPosition(0)
        }        
        return () => {
            window.removeEventListener("resize", resizeListener)
        }
    }, [selectedRpm, sliderRef.current])


    if (cwtUrl) {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                {freqRange ?
                    <>
                        <div style={{ width: '100%', height: 'auto', display: 'flex', position: 'relative', zIndex: 1 }}>
                            <img style={{ width: '100%', minHeight: '300px', height: 'auto', }} src={`${cwtUrl}`} />
                            <div ref={sliderRef} style={{ position: 'absolute', zIndex: 3, top: `${sliderPosition}px`, width: '100%', height: '2px', backgroundColor: 'red' }}></div>
                        </div>
                        <div style={{ width: '100%', maxWidth: '100%', display: 'flex', justifyContent: 'left', marginLeft: '1em', marginRight: '1em' }}>

                            <input type="range" style={{ appearance: 'slider-vertical', height: 'auto', maxHeight: '100%', width: '1em', }} min={0} max={cutoffFreq} step={1} value={selectedRpm} list={freqRange} onChange={(event) => setselectedRpm(event.target.value)} />
                            <div style={{ maxHeight: '100%', height: 'auto', display: 'flex', paddingLeft: '1em', flexDirection: 'column' }}>
                                <div><strong>Frequency Range (vertical axis): </strong><br></br>
                                    {freqRange[0].toFixed(1)} hz - {freqRange[freqRange.length - 1].toFixed(1)} hz
                                </div>
                                <div><strong>Samples (horizontal axis): </strong><br></br>
                                    {sampleCount} @ {sampleRate} hz
                                </div>
                                <div><strong>Selected Frequency: </strong><br></br>{freqRange[selectedRpm].toFixed(2)} hz</div>
                            </div>
                        </div>
                    </> : <div>loading...</div>}
            </div>

        )
    } else {
        return <div></div>
    }

}
export default CWT
