import * as React from 'react'
import '@aws-amplify/ui-react/styles.css'

export default function Layout ({user, signOut, children, config}) {
    const wrapperStyle = {
        display: 'flex', 
        flexDirection: 'column', 
        width: '100%',
        backgroundColor: config.bgcolor,
        paddingTop: '1em'
    }
    const headerStyle = {
        display: 'flex', 
        width: '100%', 
        justifyContent: 'space-between',
        paddingLeft: '2em',
        paddingRight: '2em'
    }
    const controlStyle = {
        cursor: 'pointer', 
        fontWeight: 'bold', 
        alignItems: 'right'
    }

    return(
        <div style={{display: 'flex', flexDirection: 'column', width: '100%',minHeight: '100vh', backgroundColor: config.bgcolor, padding: '4px'}}>
            <div style={{display: 'flex', width: '100%', paddingLeft: '1em', paddingRight: '1em', justifyContent: 'space-between', backgroundColor: config.paper_bgcolor, border: '1px solid grey', borderRadius: '8px', marginBottom: '4px'}}>
                <div>{user.username}</div>
                <div style={controlStyle} onClick={signOut}>signout</div>
            </div>
            {children}
        </div>
    )
}
