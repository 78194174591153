import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

export default function LineChart({lineData, layout, config, onClickHandler= () => {}}) {
    const [resizeListener, setResizeListener] = React.useState()
    const responsiveChartRef = React.useRef()
    const [managedLayout, setManagedLayout] = React.useState(layout)
    const [dataHash, setDataHash] = useState(null)


    useEffect(() => {
      managedLayout.shapes = layout.shapes
      managedLayout.xaxis.range = layout.xaxis.range
      setManagedLayout(managedLayout)
    }, [layout])

    useEffect(() => {
      window.removeEventListener("resize", resizeListener)
      let rsListener = window.addEventListener("resize", () => {
        if(responsiveChartRef.current){
          const elREF = responsiveChartRef.current;
          const el = elREF;
          const copiedLayout = Object.assign({}, managedLayout);
          const newW = el.parentElement.getBoundingClientRect().width;
          const newH = el.parentElement.getBoundingClientRect().height;
          copiedLayout.width = newW-6.5
          copiedLayout.height = newH-6.5
          setManagedLayout(copiedLayout)
        }
      });
      setResizeListener(rsListener)
      return () => {
        window.removeEventListener("resize", resizeListener)
      }
    }, [responsiveChartRef.current])
    

    useEffect(() => {
      if(responsiveChartRef.current){
        const elREF = responsiveChartRef.current;
        const el = elREF;
        const copiedLayout = Object.assign({}, managedLayout);
        const newW = el.parentElement.getBoundingClientRect().width;
        const newH = el.parentElement.getBoundingClientRect().height;
        copiedLayout.width = newW-6.5
        copiedLayout.height = newH-6.5
        // console.log('width', newW, 'height', newH)
        setManagedLayout(copiedLayout)
      }
    }, [lineData])

    return (
        <div style={{display: 'flex'}} ref={responsiveChartRef}>
          <Plot
              data={lineData}
              onClick={onClickHandler}
              layout={managedLayout}
              config={{displaylogo: false, modeBarButtonsToRemove: ['resetScale2d','zoomIn2d','zoomOut2d','autoScale2d', 'lasso2d','toImage','zoom2d','pan2d','select2d']}}
          />
        </div>
        )
}
