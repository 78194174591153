import React, { useEffect, useState } from 'react'
import LineChart from '../LineChart/LineChart';
import { apiRequest } from '../../App'
import {  buildStackedTimeSeriesScatter } from '../../lib/mungers'
import DashboardRow from '../DashboardRow/DashboardRow';
import Layout from '../Layout/Layout'
import {
    Link
} from "react-router-dom";
import useMediaQuery from "../../Hooks/useMediaQuery"
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';

export default function PlanningDashboard({ config, user, signOut }) {
    const [selectedProjectionModel, setSelectedProjectionModel] = useState(null)
    const isMedium = useMediaQuery('(max-width: 720px)')
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());
    const [validDateRange, setValidDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 30)), new Date()])
    const [isValidDateRange, setIsValidDateRange] = useState(false)
    const [rangeValidationMessage, setRangeValidationMessage] = useState(null)
    const [processVelocityData, setProcessVelocityData] = useState(null)
    const [stackedPanelData, setStackedPanelData] = useState(null)
    const [stackedPanelLayout, setStackedPanelLayout] = useState(null)
    const [timeBucket, setTimeBucket] = useState('1 hour')
    const [processData, setProcessData] = useState([])

    const onSelectedStartDateChange = (date) => {
        setStartDate(date);
    }

    const onSelectedEndDateChange = (date) => {
        setEndDate(date)
    }

    let buildStackedPanelData = (inpt) => {
        let magSeries = []

        if (!inpt || inpt.length === 0) {
            return null
        }

        for (let i of inpt) {
            let observations = Object.values(i)
            let projections = []
            let process = i.process
            let events = i.events || []

            magSeries.push({ observations, projections, process, events })
        }

        if (magSeries.length === 0) {
            return null
        }
        if (selectedProjectionModel && magSeries.length > 0 && magSeries.length > 0) {
            return buildStackedTimeSeriesScatter({ inpt: magSeries, htmlId: "facilityAggregateProjection", labels: inpt.labels, showProjection: true })

        } else if (magSeries.length > 0 && magSeries.length > 0) {
            return buildStackedTimeSeriesScatter({ inpt: magSeries, htmlId: "facilityAggregateProjection", labels: inpt.labels, showProjection: false })
        } else {
            return null
        }
    }

    const timeBucketMap = {
        "1 day": "oneday",
        "1 hour": "onehr",
        "4 hour": "fourhr",
        "5 min": "fivemin",
    }

    const getTimeBucket = (bucket) => {
        let timeBucket = timeBucketMap[bucket]
        if (timeBucket === undefined) {
            return "oneday"
        }
        return timeBucket

    }

    useEffect(() => {
        if (processVelocityData) {
            let builtDat = buildStackedPanelData(processVelocityData)
            if (builtDat) {
                setStackedPanelData(builtDat.data)
                setStackedPanelLayout(builtDat.layout)
            } else {
                toast('No data for selected range', { toastId: 'no-data-toast' })
            }
        }
    }, [processVelocityData, selectedProjectionModel, timeBucket])

    useEffect(() => {
        if ((!isValidDateRange || !validDateRange) && !config.mode.demo) {
            return
        }
        const groupBy = key => array =>
            array.reduce((objectsByKeyValue, obj) => {
            const value = obj[key];
            objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
            return objectsByKeyValue;
        }, {});

        async function fetchProjectionMetrics() {
            let apiBase = process.env.REACT_APP_API_URL
            let bucket = getTimeBucket(timeBucket)
    
            let processApiUrl = `${apiBase}/v1/process/velocity/${bucket}?time_from=${validDateRange[0].toISOString()}&time_to=${validDateRange[1].toISOString()}`

            let response = await Promise.all([
                apiRequest(`${apiBase}/v1/process`),
                apiRequest(processApiUrl)
            ])

            let processes = response[0]
            let velocity = response[1] 

            for(let p of processes){
                p.events = velocity.events.filter((e, i) => { return p.name === e.name })
            }

            setProcessData(processes)

            const groupByName = groupBy("name")
            const output = groupByName(velocity.observations)
            const e = groupByName(velocity.events)
            let processRawDataRes = []              
            for (var key in output) {
                let pp = {}
                let ee = []

                const dataMetric = output[key][0]
                for (let p in processes) {
                    // match up process velocity to the process record
                    if (dataMetric.id_ == processes[p].process_id) {
                        pp = processes[p]
                        break
                    }
                }
                processRawDataRes.push({...output[key], process: pp, events: e[key] || []})
            }
            // console.log(processRawDataRes)
            setProcessVelocityData(processRawDataRes)
    }

        fetchProjectionMetrics()

    }, [config.mode.demo, validDateRange, timeBucket])


    const renderProcessEquipment = (equipment) => {
        equipment.sort((a, b) => (a.display_meta.order > b.display_meta.order) ? 1 : -1)
        return equipment.map((v, i) => {
            let equipmentStyle = {
                display: 'flex',
                width: '5rem',
                height: '5rem',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            }
            if (v.type === 'chiller') {
                equipmentStyle.height = '10rem';
            }
            if (['motor', 'shaft', 'pump', 'chiller'].includes(v.type)) {
                equipmentStyle.backgroundImage = `url(images/${v.type}.svg)`
            } else {
                equipmentStyle.border = '1px solid black'
            }
            let sensor_id_params = "?sensor_ids="

            for(let s of v?.sensors){
                sensor_id_params += `${s.sensor_id},`
            }
            // remove the last comma
            sensor_id_params = sensor_id_params.slice(0, -1)

            return (
                <div key={`${v.equipment_id}_equipment`} style={{ display: 'flex', width: '100%', justifyContent: 'center', flexWrap: 'wrap' }}>
                    {v?.sensors[0] ?
                        <Link to={`/equipment/${sensor_id_params}`} key={`sensor-link-${v.sensor_id}`}>
                            <div style={equipmentStyle}>
                            </div>
                        </Link> : <></>
                    }
                </div>
            )
        })
    }
    const renderProcesses = (processes) => {
        return processes.map((v, i) => {
            // console.log(v.display_meta)
            return (
                <div key={`${v.process_id}_process`} style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-between', padding: '1em' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {v.name}
                    </div>
                    {renderProcessEquipment(v.equipment)}
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center', padding: '.5em'}}>
                        <div style={{width: '3em', borderRadius: '3em', height: '.5em', backgroundColor: v?.display_meta?.color || 'lightgrey'}}></div>
                    </div>
                </div>
            )
        })
    }

    const renderProcessEvents = (processes) => {
        const columns = [
            {
                
            }
        ]
        return processes.map((p, i) => {
            return p.equipment.map((eq, i) => {
                return eq.events.map((e, i) => {
                    
                    var date = new Date(e.bucket)
                    var prettyMetric = ""
                    var unit = ""
                    if (e.metric === 'peak_shake_mag_ins_anomaly') {
                        prettyMetric = 'Peak Shake Magnitude'
                        unit = "in/s"
                    } else if (e.metric === 'peak_shock_mag_g_anomaly'){
                        prettyMetric = 'Peak Shock Magnitude'
                        unit = "g"
                    }
                    return (
                        <tr key={e.metric + e.bucket + e.equipment_id}>
                            <td style={{ whiteSpace: 'nowrap'}}>{date.toLocaleString()}</td>
                            <td style={{display:'flex'}}>{p.name} <div className='v-center'><div className='dot' style={{backgroundColor: p.display_meta.color}}></div></div></td>
                            {
                                eq.sensors.length > 0 ? 
                                <td>
                                <Link to={`/sensor/${eq.sensors[0].sensor_id}`} key={`sensor-link-${eq.sensors[0].sensor_id}`}>
                                    {e.name}
                                </Link></td> : <td>{e.name}</td>
                            }
                            
                            <td>{prettyMetric}</td>
                            <td>{e.measurement} {` (${unit})`}</td>
                        </tr>
                    )
                })
            })
        })
    }

    return (
        <Layout signOut={signOut} user={user} config={config}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DashboardRow title={'Velocity'} config={config}>
                    <div style={{ flexWrap: 'wrap', justifyContent: 'start', marginRight: '.5em', display: 'flex', flexDirection: isMedium ? 'column' : 'row', flexFlow: isMedium ? 'column-reverse' : '', width: '100%' }}>
                        {/* <div style={{ display: 'flex', marginTop: isMedium ? '.5em' : 0 }}>
                            <Ddl config={config} def={'Select'} label='Projection Model' items={['linear']} onSelected={(v) => { setSelectedProjectionModel(v) }}></Ddl>
                        </div> */}
                        <DateRangeSelector
                            start={startDate}
                            end={endDate}
                            onStartDateChange={onSelectedStartDateChange}
                            onEndDateChange={onSelectedEndDateChange}
                            onValidDateRangeChange={(dr) => setValidDateRange(dr)}
                            onDateRangeValidated={(isValid, msg) => { setIsValidDateRange(isValid); setRangeValidationMessage(msg) }}
                        />
                    </div>
                    <div style={{ display: 'flex', width: '100%' }}>
                        <div id="facilityAggregateProjection" style={{ display: 'flex', width: '94%', minHeight: '40vh', justifyContent: 'center' }}>
                            {stackedPanelData && stackedPanelLayout ? <LineChart lineData={stackedPanelData} layout={stackedPanelLayout} config={config}></LineChart> : <div>loading...</div>}
                        </div>
                    </div>
                </DashboardRow>
                <DashboardRow title={'Facility'} config={config} jc={'space-around'}>
                    {renderProcesses(processData)}
                </DashboardRow>
                <DashboardRow title={'Anomalies (last 90 days)'} config={config} jc={'space-around'}>
                    <div style={{display: 'flex', width: '100%', overflowX: 'scroll'}}>
                        <table style={{marginBottom: '1em'}}>
                            <tbody>
                                <tr>
                                    <th >Time</th>
                                    <th>Process</th>
                                    <th>Equipment</th>
                                    <th>Type</th>
                                    <th>Measurement</th>
                                </tr>
                                {renderProcessEvents(processData)}
                            </tbody>
                        </table>
                    </div>
                </DashboardRow>
            </div>
        </Layout>
    )
}
