
import './App.css'
import { Amplify, Auth } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports'
import PlanningDashboard from './Components/PlanningDashboard/PlanningDashboard';

import {
  Routes,
  Route
} from 'react-router-dom'
import React from 'react';
import SensorDetails from './Components/SensorDetails/SensorDetails';
import EquipmentDetails from './Components/EquipmentDetails/EquipmentDetails';

import FacilityLayout from './Components/FacilityLayout/FacilityLayout';

Amplify.configure(awsExports)
String.prototype.hashCode = function() {
  var hash = 0,
    i, chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
export const apiRequest = async (url, opts={}) => {
  let res = await Auth.currentSession()

  let accessToken = res.getAccessToken()

  opts = {...opts, headers: {'Authorization': `bearer ${accessToken.jwtToken}`}}
  let result = await fetch(url, opts).then(res => res.json())
  
  return result
}

export const imageRequest = async (url, opts={}) => {
  let res = await Auth.currentSession()

  let accessToken = res.getAccessToken()

  opts = {...opts, headers: {'Authorization': `bearer ${accessToken.jwtToken}`}}
  let result = await fetch(url, opts).then(res => res.blob())
  
  return result
}

export default function App ({config}) {
    const [cfng, setCfg] = React.useState(config)
    function KeyPress(e) {
      if (e.key && e.key.toLowerCase() == '`' && e.ctrlKey) {
        setCfg({...cfng, mode: {demo: !config.mode.demo}})
        config.mode.demo = !config.mode.demo;
      }
    }
    
    document.onkeydown = KeyPress;
  return (
    <Authenticator style={{position: 'absolute', top: '50%', left: '50%', transform: 'translateY(-50%) translateX(-50%)'}} hideSignUp>
      {({ signOut, user }) => (
        <React.Fragment>
            <Routes>
              <Route path='/' element={<PlanningDashboard config={config} user={user} signOut={signOut}/>} />
              <Route path='/sensor/:sensor_id' element={<SensorDetails config={config} user={user} signOut={signOut}/>} />
              <Route path='/equipment' element={<EquipmentDetails config={config} user={user} signOut={signOut}/>} />
            </Routes>
        </React.Fragment>
      )}
    </Authenticator>
  )
};
