import React, { useState, useEffect } from "react"
import config from '../../config.json';
import { buildTelemetryCandlesticks, buildStackedTimeSeriesScatter } from "../../lib/mungers";
import LineChart from "../LineChart/LineChart";
import { apiRequest } from '../../App'
import DashboardRow from '../DashboardRow/DashboardRow';
import Plot from 'react-plotly.js';
import Ddl from '../Ddl/Ddl';

import ImageBrowser from '../ImageBrowser/ImageBrowser'
import {
    Link,
    useParams
} from "react-router-dom";
import useMediaQuery from "../../Hooks/useMediaQuery"
import KineticOrbitBrowser from "../KineticOrbitBrowser/KineticOrbitBrowser";
import DateRangeSelector from '../DateRangeSelector/DateRangeSelector';
import { toast } from 'react-toastify';

const SensorDetails = ({sensor_id, onCloseClick}) => {
    const [telemetryData, setTelemetryData] = React.useState([])
    const [velocitySeries, setVelocitySeries] = React.useState(null)
    const [velocityData, setVelocityData] = useState(null)
    const [cwts, setCwts] = React.useState([])
    const [dats, setDats] = React.useState([])

    const [autoRefresh, setAutoRefresh] = React.useState(false)
    const [sensorLastSeen, setSensorLastSeen] = React.useState(null)
    const [selectedTime, setSelectedTime] = React.useState(null)
    const [selectedCwtTime, setSelectedCwtTime] = React.useState(null)
    const [selectedKOTime, setSelectedKOTime] = React.useState(null)

    const [timeClicked, setTimeClicked] = React.useState(null)
    const [sensorDetails, setSensorDetails] = React.useState(null)
    const [selectedProjectionModel, setSelectedProjectionModel] = React.useState(null)
    const isSmall = useMediaQuery('(max-width: 500px)')
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());
    const [validDateRange, setValidDateRange] = useState([new Date(new Date().setDate(new Date().getDate() - 30)), new Date()])
    const [isValidDateRange, setIsValidDateRange] = useState(false)
    const [rangeValidationMessage, setRangeValidationMessage] = useState(null)
    const [timeBucket, setTimeBucket] = useState('1 hour')
    const [stackedPanelData, setStackedPanelData] = useState(null)
    const [stackedPanelLayout, setStackedPanelLayout] = useState(null)

    useEffect(() => {
        setSelectedTime(timeClicked)
        setSelectedCwtTime(timeClicked)
        setSelectedKOTime(timeClicked)
    }, [timeClicked])

    const onSelectedStartDateChange = (date) => {
        setStartDate(date);
    }

    const onSelectedEndDateChange = (date) => {
        setEndDate(date)
    }

    const timeBucketMap = {
        "1 day": "oneday",
        "1 hour": "onehr",
        "5 min": "fivemin",
    }

    const getTimeBucket = (bucket) => {
        let timeBucket = timeBucketMap[bucket]
        if (timeBucket === undefined) {
            return "oneday"
        }
        return timeBucket

    }

    const handleRefreshClick = (e) => {
        setAutoRefresh(!autoRefresh)
    }

    const onLineChartClickHandler = (e) => {
        setTimeClicked(e.points[0].x)
    }

    const getVelocitySeries = (inpt, sensorDetails) => {
        if(sensorDetails && sensorDetails.events){
            inpt.events = sensorDetails.events
            inpt.process = {display_meta: sensorDetails.display_meta}
        }
        if (selectedProjectionModel) {
            return buildStackedTimeSeriesScatter({ inpt: [inpt], htmlId: "sensorAggregateProjection", labels: inpt.labels, showProjection: true, selectedTime: selectedTime })
        } else {
            return buildStackedTimeSeriesScatter({ inpt: [inpt], htmlId: "sensorAggregateProjection", labels: inpt.labels, showProjection: false, selectedTime: selectedTime })
        }
    }
    const handleKOItemSelected = ({ item, trigger }) => {
        if (trigger !== 'selectedTime') {
            setSelectedKOTime(item.time_start)
            setSelectedTime(item.time_start)
        }
    }
    const handleCwtItemSelected = ({ item, trigger }) => {
        if (trigger !== 'selectedTime' && trigger !=='cutoff') {
            setSelectedCwtTime(item.time_start)
            setSelectedTime(item.time_start)
        }
    }
    useEffect(() => {
        if (velocityData) {
            let builtDat = getVelocitySeries(velocityData, sensorDetails)
            if (builtDat) {
                setStackedPanelData(builtDat.data)
                setStackedPanelLayout(builtDat.layout)
            } else {
                toast('No data for selected range', { toastId: 'no-data-toast' })
            }
        }

    }, [velocityData, selectedTime, timeBucket, selectedProjectionModel, sensorDetails])

    useEffect(() => {
        if (autoRefresh) {
            const intervalId = setInterval(() => {
                setStartDate(new Date(new Date().setDate(new Date().getDate() - 1)))
                setEndDate(new Date())
            }, 10000)
            return () => clearInterval(intervalId)
        }

    }, [autoRefresh])

    const getData = () => {
        if ((!isValidDateRange || !validDateRange) && !config.mode.demo) {
            return
        }
        let bucket = getTimeBucket(timeBucket)

        async function fetchSensorImages() {
            let apiBase = process.env.REACT_APP_API_URL
            let apiUrl = `${apiBase}/v1/sensor/${sensor_id}/data?time_from=${validDateRange[0].toISOString()}&time_to=${validDateRange[1].toISOString()}`

            let res = await apiRequest(apiUrl);
            res = res.map((v, i) => {
                v.time_start = new Date(v.time_start)
                return v
            }).sort((a, b) => { return a.time_start > b.time_start })
            setCwts(res)
        }
        async function fetchSensorDetails() {
            let apiBase = process.env.REACT_APP_API_URL
            let apiUrl = `${apiBase}/v1/sensor/${sensor_id}`
            let res = await apiRequest(apiUrl);

            setSensorDetails(res)
            setSensorLastSeen(new Date(res.last_seen))
        }
        async function fetchSensortelemetry() {
            let apiBase = process.env.REACT_APP_API_URL
            let apiUrl = `${apiBase}/v1/sensor/${sensor_id}/telemetry/${bucket}?time_from=${validDateRange[0].toISOString()}&time_to=${validDateRange[1].toISOString()}`
            let res = await apiRequest(apiUrl);

            setTelemetryData(res)
        }

        async function fetchVelocityMetrics() {
            let apiBase = process.env.REACT_APP_API_URL
            let apiUrl = `${apiBase}/v1/sensor/${sensor_id}/velocity/${bucket}?time_from=${validDateRange[0].toISOString()}&time_to=${validDateRange[1].toISOString()}`
            let res = await apiRequest(apiUrl);

            setVelocityData(res)
        }


        fetchVelocityMetrics()
        fetchSensorDetails()
        fetchSensortelemetry()
        fetchSensorImages()

    }

    React.useEffect(() => {
        setSelectedTime(null)
        getData()
    }, [validDateRange, timeBucket])

    const renderTelemetry = () => {
        let metrics = []
        for (let t of telemetryData) {
            if (metrics.indexOf(t.metric) === -1) {
                metrics.push(t.metric)
            }
        }
        let metricCandlestickData = []
        for (let m of metrics) {
            let metricBuckets = telemetryData.filter((v, i) => {
                return v.metric === m
            })
            metricCandlestickData.push(buildTelemetryCandlesticks(metricBuckets, `metric-chart${m.metric}`, m))
        }
        metricCandlestickData.sort((a, b) => a.metric.localeCompare(b.metric))        
        return metricCandlestickData.map((m, i) => {
            return (
                <div id={`metric-chart${m.metric}`} key={`metric-chart${m.metric}`} style={{ display: 'flex', width: '100%', minHeight: '300px' }}>
                    <LineChart
                        lineData={m.data}
                        layout={m.layout}
                    />
                </div>
            )
        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '100vh', backgroundColor: config.bgcolor, padding: '4px' }}>
            <DashboardRow key='detail-row-1' title={`${sensorDetails ? sensorDetails.name : sensor_id}`} config={config}>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between', flexDirection: isSmall ? 'column' : 'row' }}>
                    <div style={{ justifyContent: 'center', whiteSpace: 'nowrap' }}>
                        {`last seen ${sensorLastSeen ? `${Math.floor(Math.abs(sensorLastSeen.getTime() - new Date().getTime()) / 1000)} seconds ago` : 'unknown'}`}
                    </div>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ flexWrap: 'wrap', marginRight: '1em', display: 'flex', flexDirection: isSmall ? 'column' : 'row', width: '100%' }}>
                            <div style={{ justifyContent: isSmall ? 'space-between' : '', display: 'flex', cursor: 'pointer', paddingTop: '5px', whiteSpace: 'nowrap', width: isSmall ? '100%' : '' }} onClick={handleRefreshClick}>
                                Auto Refresh
                                <div style={{ flexDirection: 'column', paddingLeft: '.25em', justifyContent: isSmall ? 'end' : '' }}>
                                    <input style={{ cursor: 'pointer' }} type="checkbox" checked={autoRefresh} readOnly></input>
                                </div>
                            </div>
                            {/* <div style={{ paddingBottom: '.5em' }}>
                                <Ddl config={config} def={'Select'} label='Projection Model' items={['linear']} onSelected={(v) => { setSelectedProjectionModel(v) }}></Ddl>
                            </div> */}
                            <div style={{ paddingBottom: '.5em' }}>
                                <DateRangeSelector
                                    start={startDate}
                                    end={endDate}
                                    onStartDateChange={onSelectedStartDateChange}
                                    onEndDateChange={onSelectedEndDateChange}
                                    onValidDateRangeChange={(dr) => setValidDateRange(dr)}
                                    onDateRangeValidated={(isValid, msg) => { setIsValidDateRange(isValid); setRangeValidationMessage(msg) }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardRow>
            <DashboardRow key={`detail-row-4`} title={'Velocity'} config={config}>
                <div id={"sensorAggregateProjection"} style={{ display: 'flex', width: '100%', minHeight: '300px', justifyContent: 'center' }}>
                    {stackedPanelData && stackedPanelLayout ? <LineChart lineData={stackedPanelData} layout={stackedPanelLayout} config={config} onClickHandler={onLineChartClickHandler}></LineChart> : <div>loading...</div>}
                </div>
            </DashboardRow>
            <DashboardRow key='detail-row-3' title={`Waterfall`} config={config}>
                <ImageBrowser images={cwts} onCwtSelected={handleCwtItemSelected} config={config} selectedTime={selectedKOTime}></ImageBrowser>
            </DashboardRow>
            {/* <DashboardRow key='detail-row-4' title={`Kinetic Orbit`} config={config}>
                <KineticOrbitBrowser dats={dats} onKOSelected={handleKOItemSelected} config={config} selectedTime={selectedCwtTime}></KineticOrbitBrowser>
            </DashboardRow>             */}
            <DashboardRow key='detail-row-2' title={`Telemetry`} config={config}>
                {telemetryData.length > 0 ? renderTelemetry() : <div>...</div>}
            </DashboardRow>
        </div>
    )
}
export default SensorDetails
