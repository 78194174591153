import React, { useEffect, useState } from 'react'
import useMediaQuery from "../../Hooks/useMediaQuery"
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { toast } from 'react-toastify';

export default function DateRangeSelecor({start, end, onStartDateChange, onEndDateChange, onValidDateRangeChange, onDateRangeValidated}) {
    const isSmall = useMediaQuery('(max-width: 500px)')
    const isMedium = useMediaQuery('(max-width: 720px)')
    const [validDateRange, setValidDateRange] = useState()
    const [startCalendarOpen, setStartCalendarOpen] = useState(false)
    const [endCalendarOpen, setEndCalendarOpen] = useState(false)

    const onSelectedStartDateChange = (date) => {
        onStartDateChange(date);
    }

    const onSelectedEndDateChange = (date) => {
        onEndDateChange(date)
    }

    useEffect(() => {
        if(!startCalendarOpen && !endCalendarOpen){
            let [isValid, validationMsg] = validateDateRange(start, end)
            onDateRangeValidated(isValid, validationMsg)
            if(isValid){
                setValidDateRange([start, end])
            }else{
                toast(validationMsg)
            }
        }
    }, [startCalendarOpen, endCalendarOpen, start, end])

    useEffect(() => {
        onValidDateRangeChange(validDateRange)
    }, [validDateRange])

    const validateDateRange = (start, end) => {
        if(start > end){
            return [false, 'Start must be before End']
        }
        return [true, null]
    }

    return(                        
        <div style={{justifyContent: isSmall ? 'end' : ''}}>
            <div style={{display: 'flex', flexWrap: 'wrap', flexDirection: isMedium ? 'column' : '', marginRight: isMedium ? 0 : '2em', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', marginBottom: isMedium ? '.5em' : 0, justifyContent: isMedium ? 'space-between': '', width: isMedium ? '100%' : ''}}>
                    <div style={{display: 'flex',  marginRight: isMedium ? 0 : '.5em', paddingTop: '4px'}}>
                        Start
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <DatePicker
                            selected={start}
                            onChange={(date) => onSelectedStartDateChange(date)}
                            onCalendarOpen={(() => {setStartCalendarOpen(true)})}
                            onCalendarClose={(() => {setStartCalendarOpen(false)})}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={240}
                            timeCaption="time"
                            dateFormat="M/d/yy HH:mm"

                        />
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: isMedium ? 'space-between': '', width: isMedium ? '100%' : ''}}>
                    <div style={{display: 'flex', marginLeft: isMedium ? 0 : '.5em',marginRight: '.5em', paddingTop: '4px'}}>
                        End
                    </div>
                        <DatePicker
                            selected={end}
                            onChange={(date) => onSelectedEndDateChange(date)}
                            onCalendarOpen={(() => {setEndCalendarOpen(true)})}
                            onCalendarClose={(() => {setEndCalendarOpen(false)})}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={240}
                            timeCaption="time"
                            dateFormat="M/d/yy HH:mm"
                        />
                </div>
            </div>
        </div>
    )
}