import React from "react"
import { downloadFile } from "../../lib/mungers";
import CWT from "../CWT/CWT";
import Ddl from '../Ddl/Ddl';

const ImageBrowser = ({images, config, onCwtSelected=() => {}, selectedTime}) => {
    const [selectedImage, setSelectedImage] = React.useState(false)
    const [selectedIndex, setSelectedIndex] = React.useState(false)
    const [sliderPosition, setSliderPosition] = React.useState(0)
    const [userSelectedImage, setUserSelectedImage] = React.useState(false)
    const [latestImage, setLatestImage] = React.useState(null)
    const [cutoffFreq, setCutoffFreq] = React.useState(200)

    const sliderRef = React.useRef()

    React.useEffect(() => {
        if(!images || images.length === 0){
            return
        }

        if(!latestImage){
            let li = new Date(images[images.length-1].time_start)
            setLatestImage(li)
            setSelectedImage(images[images.length-1])
            setSelectedIndex(images.length-1)
            setSliderPosition(sliderRef.current.parentElement.getBoundingClientRect().width-8)
        }else{
            let ni = new Date(images[images.length-1].time_start)

            if(ni !== latestImage){
                setLatestImage(ni)
                setSelectedImage(images[images.length-1])
                setSelectedIndex(images.length-1)
                setSliderPosition(sliderRef.current.parentElement.getBoundingClientRect().width-8)
            }
        }
      }, [images])

      React.useEffect(() => {
        if(selectedTime){
            let min = null
            let st = new Date(selectedTime)
            for(let i=0; i < images.length; i++){
                if(!min || Math.abs(new Date(images[i].time_start) - st) < min){
                    min = Math.abs(new Date(images[i].time_start) - st)
                }else if(Math.abs(new Date(images[i].time_start) - st) > min){
                    const ni = i > 0 ? i - 1 : i
                    setSelectedImage(images[ni])
                    onCwtSelected({item: images[ni], trigger: 'selectedTime'})
                    setSelectedIndex(ni)
                    const rp = ni/images.length
                    const np = Math.floor(rp*sliderRef.current.parentElement.getBoundingClientRect().width)
                    setSliderPosition(np)
                    break;
                }
            }
        }
      }, [selectedTime])

    const handleMouseClick = (e) => {
        setUserSelectedImage(true)
        const localX = e.clientX - e.target.offsetLeft;
        setSliderPosition(localX)
        const rp = localX/sliderRef.current.parentElement.getBoundingClientRect().width
        const ni = Math.floor(images.length*rp)
        setSelectedIndex(ni)
        setSelectedImage(images[ni])
        onCwtSelected({item: images[ni], trigger: 'click'})
    }

    const nextImg = () => {
        setUserSelectedImage(true)
        if(selectedIndex === images.length-1){
            return
        }
        const ni = selectedIndex + 1
        setSelectedImage(images[ni])
        onCwtSelected({item: images[ni], trigger: 'next'})
        setSelectedIndex(ni)
        const rp = ni/images.length
        const np = Math.floor(rp*sliderRef.current.parentElement.getBoundingClientRect().width)
        setSliderPosition(np)
    }

    const prevImg = () => {
        setUserSelectedImage(true)
        if(selectedIndex === 0){
            return
        }
        const ni = selectedIndex - 1
        setSelectedImage(images[ni])
        onCwtSelected({item: images[ni], trigger: 'prev'})
        setSelectedIndex(ni)
        const rp = ni/images.length
        const np = Math.floor(rp*sliderRef.current.parentElement.getBoundingClientRect().width)
        setSliderPosition(np)
    }

    return(
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', paddingBottom: '1em'}}>
            <div style={{display: 'flex', width: '100%', backgroundColor: config.paper_bgcolor}}>

                {selectedImage &&
                    (   <>
                            <div style={{paddingLeft: '.25em', paddingRight: '.25em', paddingTop: '.12em'} }> {selectedImage.time_start.toLocaleString()} </div>
                            
                            {/* HIDING CUTOFF FREQ OPTION FOR FIRST LAUNCH JM -2/09/2023 <div style={{ }}>
                                <div style={{cursor: 'pointer',paddingLeft: '.25em', paddingRight: '.25em', paddingTop: '.12em', marginRight: '0.5em', display: 'flex', backgroundColor: config.plot_bgcolor, borderRadius: config.borderRadius, border: `1px solid ${config['buttonBorder']}`}} onClick={() => downloadFile('data', selectedImage.resource, `data-${selectedImage.sensor_id}-${selectedImage.time_start.toLocaleString()}.dat`, cutoffFreq)}>download file</div>
                                <Ddl config={config} def={'select'} label='Cutoff (hz)' items={['200', '1000']} onSelected={(v) => { setCutoffFreq(v) }}></Ddl>
                            </div> */}

                        </>) 
                }
                
            </div>
            <div style={{display: 'flex', width: '100%', minHeight: '300px', paddingTop: '.5em', justifyContent: 'center', backgroundColor: config.paper_bgcolor}}>
                {selectedImage ? <CWT resource={selectedImage.resource} cutoffFreq={cutoffFreq} /> : <div>loading...</div>}
            </div>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <div onClick={prevImg} style={{paddingTop: '2px', color: 'black', cursor: 'pointer', display: 'flex', width: '2%'}}>&#10094;</div>
                <div onClick={handleMouseClick} style={{cursor: 'pointer', display: 'flex', width: '96%', marginTop: '6px', height: '20px', backgroundColor: config.alphaSecondaryLineColor}}>
                    <div ref={sliderRef} style={{position: 'relative', top: -2, left: `${sliderPosition}px`,width: '8px', height: '25px', backgroundColor: config.secondaryLineColor}}></div>
                </div>
                <div onClick={nextImg} style={{paddingTop: '2px', color: 'black', cursor: 'pointer', display: 'flex', width: '2%', justifyContent: 'end'}}>&#10095;</div>
            </div>
        </div>
    )
}
export default ImageBrowser
