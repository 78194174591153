import { MarginOutlined } from '@mui/icons-material'
import React from 'react'
import useMediaQuery from "../../Hooks/useMediaQuery"

export default function DashboardRow({ title, children, config, jc, handleEditClick, isEditing }) {
    const isSmall = useMediaQuery('(max-width: 500px)')
    const isMedium = useMediaQuery('(max-width: 720px)')

    const wrapperStyle = { 
        display: 'flex', 
        backgroundColor: config.paper_bgcolor, 
        borderRadius: config.borderRadius ,
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        paddingLeft: '1em', 
        paddingRight: '1em', 
        paddingTop: '1em', 
        width: '100%' ,
        border: '1px solid grey',
        borderRadius: '8px',
        marginBottom: '4px'
    }

    const titleStyle = { 
        margin: 0, 
        padding: 0, 
        fontWeight: 'bold',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1em'
    }

    const bodyStyle = { 
        display: 'flex', 
        justifyContent: jc ? jc :'space-between', 
        flexWrap: 'wrap' 
    }
    return (
        <div style={wrapperStyle}>
            <div style={titleStyle}>
                <div style={{}}>
                    {title}
                </div>
                {
                    handleEditClick ? <div style={{cursor: 'pointer'}} onClick={handleEditClick}>{isEditing ? 'hide layout': 'show layout'}</div> : ""
                }
            </div>

            <div style={bodyStyle}>
                {children}
            </div>
        </div>
    )
}
