import { a, input } from 'aws-amplify';
import config from '../config.json';
import { generalMachine, getLabel } from './iso';
import { imageRequest } from "../App"

export const getYRange = (inpt) => {
    let min, max
  
    for(let d of inpt){
      if(!min){
        min = d.high
      }
      if(!max){
        max = d.high
      }
      if(d.min < min){
        min = d.high
      }
      if(d.high > max){
        max = d.high
      }
    }

    const res = [min, max]
    
    return res
  }

export const buildStackedTimeSeriesScatter = ({inpt, htmlId, inptAxis, metric, labels=[], selectedTime, showProjection}) => {
    // parse dates and sort
    let data = []

    let flip = false
    let input_data = []

    // normalize input ranges
    let buckets = []
    for(let i of inpt){
      buckets = [...buckets, ...i.observations.map((v, i) => {return v.bucket})]
    }
    for(let i of inpt){
      for(let b of buckets){
        if(i.observations.filter((v, i) => {return v.bucket === b}).length === 0){
          i.observations.push({
            ...i.observations[0],
            bucket: b,
            high: 0
          })
        }
      }
    }

    // normalize input ranges
    buckets = []
    for(let i of inpt){
      buckets = [...buckets, ...i.projections.map((v, i) => {return v.bucket})]
    }
    for(let i of inpt){
      for(let b of buckets){
        if(i.projections.filter((v, i) => {return v.bucket === b}).length === 0){
          i.projections.push({
            ...i.projections[0],
            bucket: b,
            high: 0
          })
        }
      }
    }

    for(let i of inpt){
      i.observations = i.observations.map((v, i) => {
        v.bucket = new Date(v.bucket)
        return v
      }).sort((a,b) => {return a.bucket > b.bucket})

      i.observations = i.observations.map((v, i) => {
        v.text = `${v.sensor_id} ${v.metric} ${v.axis}`
        v.sample_type = ""
        return v
      })
      let x_data = i.observations.map((v, i) => {
        return v.bucket
      })
      let y_data = i.observations.map((v, i) => {
        return v.high
      })
      input_data =  i.observations

      if (showProjection) {
        i.projections = i.projections.map((v, i) => {
          v.bucket = new Date(v.bucket)
          return v
        }).sort((a,b) => {return a.bucket > b.bucket})
  
        i.projections = i.projections.map((v, i) => {
          v.text = `${v.sensor_id} ${v.metric} ${v.axis}`
          v.sample_type = "est"
          return v
        })     
        let x_data_proj = i.projections.map((v, i) => {
          return v.bucket
        })
        let y_data_proj = i.projections.map((v, i) => {
          return v.high
        })        
        x_data.push(...x_data_proj)
        y_data.push(...y_data_proj)
        input_data.push(...i.projections)
      }
      let highTrace = {
        x: x_data,
        y: y_data,
        
        text: input_data.map((v, i) => {return v.text + 'high'}),
        customdata: input_data.map((v, i) => {return [[getLabel(v.high)],[v.high], [v.bucket.toLocaleString()],[v.name],[v.sample_type]]}),
        hovertemplate: "<br><b>%{customdata[3]}</b><br><b>Summary Ending At:</b><br>%{customdata[2]}<br><b>in/s peak:</b><br> %{customdata[1]}<b> %{customdata[4]}</b>",
        fillcolor: i.process?.display_meta?.color,
        line: {color: i.process?.display_meta?.color},
        type: 'scatter', 
        name: '',
        xaxis: 'x', 
        yaxis: 'y', 
        mode: 'none',
        stackgroup: 'sensor_stack'
      }
      flip = !flip
      data.push(highTrace)
    }

    let plotElement = document.getElementById(`${htmlId}`)

    let layout = {
        shapes: [],
      dragmode: 'zoom', 
      font: {
        color: config.fontColor
      },
      paper_bgcolor: config.paper_bgcolor,
      plot_bgcolor: config.plot_bgcolor,
      autosize: false,
      width: plotElement && plotElement.getBoundingClientRect().width || 500,
      margin: {l:40, t:40, r:40, b:40},
    
      showlegend: false, 
    
      xaxis: {
        // https://github.com/d3/d3-time-format
        tickformat: "%m/%d %X",
        gridcolor: config.plot_gridcolor,
        gridwidth: 1,
        visible: true,
        fixedrange: false,
        type: 'date',
        range: [input_data?[0]?.bucket : 0, input_data?[input_data?.length-1]?.bucket: 0],
        autotick: true,
        ticks: 'outside',
        title: '', 
        type: 'date',
      }, 
    
      yaxis: {
        autorange: true, 
        gridcolor: config.plot_gridcolor,
        gridwidth: 1,
        type: 'linear'
      },
      yaxis2: {
        autorange: true, 
        gridcolor: config.plot_gridcolor,
        gridwidth: 10,
        type: 'linear',
          overlaying: 'y'
        },
    };

    let maxY = 0

    // match events to their closest observation bucket
    for(let i of inpt){
      for(let e of i.events ?? []){
        let closest = i.observations.reduce((prev, curr) => {
          return (Math.abs(curr.bucket - new Date(e.bucket)) < Math.abs(prev.bucket - new Date(e.bucket)) ? curr : prev);
        });
        e.timestamp = closest.bucket
      }
    }

    for(let i of inpt){
      for(let o of i.observations){
        if(o.high > maxY){
          maxY = o.high
        }
      }
    }

    for(let i of inpt)
    {
      for(let e of i.events ?? []){
        // console.log('got event', i)
        layout.shapes.push(          
          {
            type: 'line',
            xref:'x', yref:'paper',            
            x0: e.timestamp,
            y0: 0,
            x1: e.timestamp,
            y1: 1,
            line: {
              color: i.process.display_meta.color,
              width: 1,
            }
          }
        )
      }
    }
    if(selectedTime){
      layout.shapes.push(          
        {
          type: 'line',
          xref:'x', yref:'paper',           
          x0: selectedTime,
          y0: 0,
          x1: selectedTime,
          y1: 1,
          line: {
            color: 'red',
            width: 1,
          }
        }
      )
    }
    
    return { layout, data , htmlId, inptAxis, metric}
  }

export const buildTelemetryCandlesticks = (inpt, htmlId, metric) => {
  let title = metric

  if (title === "board_temp") {
    title = "Temp (C)"
  } else if (title === "rssi_dBm") {
    title = "Signal Strength (dBm)"
  } else if (title === "a_peak_g") {
    title = "Peak Shock (G)"
  }

  inpt = inpt.map((v, i) => {
      if (title === "Temp (C)") {
        v.high = v.high / 10.0
        v.low = v.low / 10.0
        v.open = v.open / 10.0
        v.close = v.close / 10.0
        v.avg = v.avg / 10.0
      }
        v.bucket = new Date(v.bucket)
        return v
    }).sort((a,b) => {return a.bucket > b.bucket})


    
    let t1 = {
      close: [],
      decreasing: {line: {color: config.decreasing}}, 
      high: [],
      increasing: {line: {color: config.increasing}}, 
      line: {color: config.lineColor}, 
      low: [],
      open: [],
      type: 'candlestick', 
      xaxis: 'x', 
      yaxis: 'y',
      x: inpt.map((v, i) => {
        return v.bucket
      }),
    }
    const plotElement = document.getElementById(htmlId)
    var layout = {

    
      height: 350,
      title: {text: title},
      dragmode: 'zoom', 
      font: {color: config.fontColor},
      paper_bgcolor: config.paper_bgcolor,
      plot_bgcolor: config.plot_bgcolor,
      autosize: false,
      width: plotElement && plotElement.getBoundingClientRect().width || 500,
      // margin: {
    
      //   r: 30, 
    
      //   t: 25, 
    
      //   b: 40, 
    
      //   l: 60
    
      // }, 
    
      showlegend: false, 
    
      xaxis: {
        // https://github.com/d3/d3-time-format
        tickformat: "%m/%d %X",
        gridcolor: config.plot_gridcolor,
        gridwidth: 1,
        visible: true,
        fixedrange: false,
        type: 'dateTime',
        range: [inpt?[0]?.bucket : 0, inpt?[inpt?.length-1]?.bucket: 0],
        autotick: true,
        ticks: 'outside',
        title: ''
      }, 
    
      yaxis: {
        autorange: true, 
        gridcolor: config.plot_gridcolor,
        gridwidth: 1,
        type: 'linear'
      }
    
    };
  
    t1.high = inpt.map((v, i) => {
      return v.high
    })
  
    t1.low = inpt.map((v, i) => {
      return v.low
    })
  
    t1.open = inpt.map((v, i) => {
      return v.open
    })
  
    t1.close = inpt.map((v, i) => {
      return v.close
    })
  
  
    var data = [t1];
  
    return {data, layout, metric}
  }

export  const downloadFile = async (type, resource, fileName, cutoffFreq) => {
  let newBlob = await imageRequest(`${process.env.REACT_APP_API_URL}/${type}/${resource}`)
  let urlCreator = window.URL || window.webkitURL
  let newCwtUrl = urlCreator.createObjectURL(newBlob)  
  const aElement = document.createElement('a');
  aElement.setAttribute('download', fileName);
  aElement.href = newCwtUrl;
  aElement.setAttribute('target', '_blank');
  aElement.click();

  };  