export const generalMachine = {
    classOne: {
        Good: [0, 0.04],
        Satisfactory: [0.04, 0.10],
        Unsatisfactory: [0.10, 0.40],
        Unacceptable: [0.40, 2.51]
    },
    classTwo: {
        Good: [0, 0.06],
        Satisfactory: [0.06, 0.16],
        Unsatisfactory: [0.16, 0.5],
        Unacceptable: [0.5, 2.51]
    },
    classThree: {
        Good: [0, 0.10],
        Satisfactory: [0.10, 0.40],
        Unsatisfactory: [0.40, 1.0],
        Unacceptable: [1.0, 2.51]
    },
    classFour: {
        Good: [0, 0.25],
        Satisfactory: [0.25, 0.62],
        Unsatisfactory: [0.62, 1.56],
        Unacceptable: [1.56, 2.51]
    }
}

const labels = ['Good', 'Satisfactory', 'Unsatisfactory', 'Unacceptable']

const mtypes = {
    generalMachine
}

export const getLabel = (val, mtype='generalMachine', cls='classTwo') => {
    for(let label of labels){
        if(val >= mtypes[mtype][cls][label][0] && val <= mtypes[mtype][cls][label][1]){
            return label
        }
    }
    return 'Unknown'
}